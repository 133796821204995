import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import {
	Button as ReactAriaButton,
	type ButtonProps as ReactAriaButtonProps,
} from 'react-aria-components'

import { cn } from '#app/utils/misc.tsx'

const buttonVariants = cva(
	'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors outline-none focus-visible:ring-4 focus-within:ring-4 ring-ring ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
	{
		variants: {
			variant: {
				default:
					'bg-lapis dark:bg-lavender text-white hover:hover-lapis dark:bg-lavender dark:hover:bg-hover-lavender dark:text-black  transition-colors',
				destructive: 'bg-destructive text-destructive-foreground hover:bg-red',
				outline:
					'border border-input bg-background hover:bg-accent hover:text-accent-foreground dark:bg-midnight dark:border-charcoal',
				secondary:
					'bg-secondary text-secondary-foreground hover:bg-secondary/80',
				ghost:
					'hover:bg-hover-lapis hover:text-white dark:hover:bg-hover-lavender dark:hover:text-black',
				link: 'text-primary underline-offset-4 hover:underline',
				microsoft:
					'bg-white dark:bg-[#2f2f2f] text-[#5E5E5E] dark:text-white border border-[#8C8C8C] dark:border-[#2F2F2F]',
				promptList:
					'whitespace-nowrap rounded-md border-2 border-lapis bg-[#F7F4FF] p-2 text-sm text-lapis hover:border-hover-lapis hover:bg-[#230077]/5 hover:text-hover-lapis dark:border-[#B6A2FF] dark:bg-[#D7C5FF]/5 dark:text-[#B6A2FF] dark:hover:border-[#D7C5FF] dark:hover:bg-black dark:hover:text-hover-lavender',
			},
			size: {
				default: 'h-10 px-4 py-2',
				wide: 'px-24 py-5',
				sm: 'h-9 rounded-md px-3',
				lg: 'h-11 rounded-md px-8',
				pill: 'px-12 py-3 leading-3',
				icon: 'h-10 w-10',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
)

export interface ButtonProps
	extends ReactAriaButtonProps,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, ...props }, ref) => {
		return (
			<ReactAriaButton
				className={values =>
					cn(
						buttonVariants({
							variant,
							size,
							className:
								typeof className === 'function' ? className(values) : className,
						}),
					)
				}
				ref={ref}
				{...props}
			/>
		)
	},
)
Button.displayName = 'Button'

export { Button, buttonVariants }
